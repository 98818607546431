<style scoped lang="less">
  .person-page {
    background-color: #EFF1F7;
    display: flex;
    & > .left, & > .right {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 16px;
      color: #3C3F54;
      margin-bottom: 15px;
    }
    & > .left {
      flex: 1;
      width: 0;
      .top {
        padding: 20px;
        box-sizing: border-box;
      }
      .bottom {
        flex: 1;
        height: 0;
        display: flex;
        padding: 0 20px 20px 20px;
        position: relative;
        .left, .right {flex: 1;height: 100%;}
        .left {
          display: flex;
          flex-direction: column;
        }
        .right {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
        }
      }
    }
    & > .right {
      width: 400px;
      height: 100%;
      background-color: #FFF;
    }
  }
  .card {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    background-color: #FFF;
  }
  .full-over-height {
    flex: 1; height: 0; position: relative; display: flex;
    flex-direction: column;
  }
  
  .board-list {
    background-image: url('/static/images/rbbg.png');
    background-repeat: no-repeat;
    background-size: 210px 180px;
    background-position: right bottom;
    margin: 0 20px 20px 20px;
    .item {
      background-color: rgba(255, 255, 255, .9);
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      & + .item { margin-top: 10px; }
      .iconfont { font-size: 15px; color: rgba(35, 50, 43, .5); position: relative; top: 3px; }
      .order {margin: 0 5px;}
      .text { flex: 1; color: #3C3F54; padding: 0 14px 0 0; }
      .tim { color: #3C3F54; }
    }
  }
</style>

<template>
  <div class="person-page">
    <div class="left">
      <div class="top">
        <div class="title">我的常用</div>
        <used-menus />
      </div>
      <div class="bottom">
        <div class="left">
          <div class="title">我的预警</div>
          <waring class="card full-over-height" />
        </div>
        <div class="right">
          <div class="title">我的待办</div>
          <notice class="card full-over-height" style="padding: 20px;"></notice>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top" style="border-bottom: 1px solid rgb(203, 213, 224);">
        <v-date-picker :value="new Date()" :attributes="attributes" style="border: none; width: 100%;"/>
      </div>
      <div class="full-over-height bottom">
        <div style="font-size: 16px; color: #3C3F54;margin: 20px;"><i class="iconfont icon-a-zu1436 theme-text-color" style="font-size: 20px;"></i>公告栏</div>
        <div class="board-list full-over-height" style="overflow: auto;">
          <div class="item" v-for="item in placardList" :key="item.id">
            <i class="iconfont icon-xiaoxi"></i>
            <div class="order">{{item.id}}.</div>
            <div class="text">{{item.title}}<br />{{item.content}}</div>
            <div class="tim">{{(item.createTime || '').substr(0, 10)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  placardRequest
} from '@/api'

import UsedMenus from './menus'
import Notice from './notice'
import Waring from './waring'

export default {
  components: { UsedMenus, Notice, Waring },
  data () {
    return {
      status: {
        editForm: false
      },
      placardList: []
    }
  },
  provide () {
    return {
      authFun: this.authFun,
      authJump: this.authJump
    }
  },
  computed: {
    attributes () {
      let color = this.$store.getters.theme === 'pink' ? 'pink' : 'blue'
      return [
        {
          key: 'today',
          highlight: color,
          dates: new Date()
        }
      ]
    },
    authFun () {
      const isAdmin = (this.$store.getters.currentRole && this.$store.getters.currentRole.id === 1)
      return {
        agreementWarning: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.agreement_warning-get')),
        jobTitleWarning: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.job_title_queue_warning-get')),
        agreementTurnwarning: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.agreement_turn_warning-get')),
        wrokerEditApply: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.worker_edit-get')),
        workerJobTitleQueue: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.job_title_queue-get')),
        baseOrg: isAdmin || (this.$store.getters.currentFunKeys.includes('org.manage-get')),
        workerRew: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.rew-get')),
        workerPrint: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.worker-get')),
        workerDetail: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.worker-get')),
        resume: isAdmin || this.$authFunsProxy.getMyResume || this.$authFunsProxy.getMyOrgResume || this.$authFunsProxy.getMyOrgContainChildResume || this.$authFunsProxy.getAllResume,
        addWorker: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.edit-base'))
      }
    },
  },
  methods: {
    authJump (auth, name) {
      if (this.authFun[auth]) {
        this.$router.push({name})
      }
    },
    async loadPlacardData () {
      this.placardList = await placardRequest.get({status: '展示'})
    }
  },
  mounted () {
    if (this.$authFunsProxy.base) {
      this.loadPlacardData()
    }
  }
}
</script>
