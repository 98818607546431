import {
  workerRequest
} from '../../api'

import {
  fileHepler
} from '@/fmlib'

const columnList = [{
  title: '序号',
  field: 'index'
},
{
  title: '姓名',
  field: 'name'
},
{
  title: '性别',
  field: 'sex'
},
{
  title: '出生年月',
  field: 'birth'
},
{
  title: '参加工作时间',
  field: 'correctionDate'
},
{
  title: '进入本单位时间',
  field: 'entryDate'
},
{
  title: '最高学历',
  field: 'highestEduEdu'
},
{
  title: '毕业时间',
  field: 'highestEduEndDate'
},
{
  title: '原聘用职务等级',
  field: 'bJobTitleTitle'
},
{
  title: '现聘任岗位名称',
  field: 'cJobTitleMLevel'
},
{
  title: '现聘任岗位等级',
  field: 'cJobTitleTitle'
},
{
  title: '现聘任期品时间',
  field: 'cJobTitleStartDate'
}]

const donwFun = async function () {
  let datas = await workerRequest.getDetail()
  datas = datas.filter(v => v.worker && v.worker.type === '在编')
  let newDataList = datas.map((v, index) => {
    v.highestEdu = v.workerEduDatas ? v.workerEduDatas.find(v2 => v2.type && v2.type.includes('最高学历')) : null
    v.worker.highestEduEdu = v.highestEdu ? v.highestEdu.edu : null
    v.worker.highestEduEndDate = v.highestEdu && v.highestEdu.endDate ? v.highestEdu.endDate.slice(0, 10) : null
    v.worker.birth = v.worker.birth ? v.worker.birth.slice(0, 10) : null
    v.worker.correctionDate = v.worker.correctionDate ? v.worker.correctionDate.slice(0, 10) : null
    v.worker.entryDate = v.worker.entryDate ? v.worker.entryDate.slice(0, 10) : null
    let workerJobTitleDatas = v.workerJobTitleDatas || []
    workerJobTitleDatas.forEach(v1 => {
      v1.hireStartDateNum = v1.hireStartDate ? new Date(v1.hireStartDate).getTime() : 0
    })
    workerJobTitleDatas.sort((a, b) => {
      return b.hireStartDateNum - a.hireStartDateNum
    })
    v.cJobTitle = workerJobTitleDatas.length > 0 ? workerJobTitleDatas[0] : null
    v.bJobTitle = workerJobTitleDatas.length > 1 ? workerJobTitleDatas[1] : null
    v.worker.cJobTitleMLevel = v.cJobTitle ? v.cJobTitle.mLevel : null
    v.worker.cJobTitleTitle = v.cJobTitle ? v.cJobTitle.jobTitleType + v.cJobTitle.jobTitleTitle + '级' : null
    v.worker.cJobTitleStartDate = v.cJobTitle && v.cJobTitle.startDate ? v.cJobTitle.startDate.slice(0, 10) : null
    v.worker.bJobTitleTitle = v.bJobTitle ? v.bJobTitle.jobTitleType + v.bJobTitle.jobTitleTitle + '级' : null
    v.worker.index = index + 1
    return v.worker
  })
  fileHepler.outPutTableData(newDataList, columnList, '人员在岗状态.xlsx')
}

export default donwFun
