<style scoped lang="less">
  .used-menus {
    overflow: auto;
    display: flex;
    position: relative;
    .item {
      width: 120px;
      transition: all .3s;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      color: #3C3F54;
      box-sizing: border-box;
      padding: 20px;
      .iconfont {
        display: inline-block;
        margin-bottom: 21px;
        height: 70px;
        width: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 30px;
        border-radius: 18px 2px 18px 18px;
        position: relative;
        .num {
          position: absolute;
          right: 5px;
          top: 5px;
          min-width: 20px;
          height: 20px;
          border-radius: 10px;
          font-size: 13px;
          color: #FFF;
          line-height: 20px;
          background-color: #EB3030;
        }
      }
      & + .item {
        margin-left: 20px;
      }
    }
  }
  .card {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    background-color: #FFF;
  }
</style>

<style lang="less">
.pink-theme {
  .used-menus {
    .item:hover {
      color: #FB6D8F !important;
    }
  }
}
.drak-theme, .blue-theme {
  .used-menus {
    .item:hover {
      color: #6B75CA !important;
    }
  }
}
</style>

<template>
  <div class="used-menus">
    <div class="card item" @click="$router.push({name: 'hrms.resume.index'})" v-if="authFun.resume">
      <i class="iconfont icon-jianli" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>员工简历</div>
    </div>
    <div v-if="false" class="card item" @click="authJump('askLeaveApply', 'hrms.worker.my_ask_leave_sp')">
      <i class="iconfont icon-a-zu1438" style="color: #0254FE;background: #F0F3F8;">
        <span v-if="askLeaveApplyCount > 0 && false" class="num">{{askLeaveApplyCount}}</span>
      </i>
      <div>请假审批</div>
    </div>
    <div class="card item" @click="status.editForm = true" v-if="workerId">
      <i class="iconfont icon-a-zujian215" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>个人信息维护</div>
    </div>
    <div class="card item" @click="authJump('wrokerEditApply', 'hrms.worker.worker_edit')" v-if="authFun.wrokerEditApply">
      <i class="iconfont icon-a-zu1440" style="color: #FB6D8F;background: #FEF0F1;">
        <span v-if="wrokerEditApplyCount > 0 && false" class="num">{{wrokerEditApplyCount}}</span>
      </i>
      <div>员工信息修改审批</div>
    </div>
    <div class="card item" @click="$router.push({name: 'hrms.worker.job_title_queue'})" v-if="authFun.workerJobTitleQueue">
      <i class="iconfont icon-a-zu1365" style="color: #E3C700;background: #FEFAF0;"></i>
      <div>当前岗位排队情况</div>
    </div>
    <div class="card item" @click="$router.push({name: 'hrms.worker.worker'})" v-if="authFun.workerPrint">
      <i class="iconfont icon-a-zujian215" style="color: #0254FE;background: #F0F3F8;"></i>
      <div>员工个人档案打印</div>
    </div>
    <div class="card item" @click="$router.push({name: 'hrms.worker.edit', query: {new: 1}})" v-if="authFun.addWorker">
      <i class="iconfont icon-add" style="font-weight: 800; color: #0254FE;background: #F0F3F8;"></i>
      <div>新增员工</div>
    </div>
    <div class="card item" @click="$router.push({name: 'org.manage'})" v-if="authFun.baseOrg">
      <i class="iconfont icon-jigoushezhi" style="color: #E3C700;background: #FEFAF0;"></i>
      <div>机构设置</div>
    </div>
    <div class="card item" @click="$router.push({name: 'hrms.worker.rew'})" v-if="authFun.workerRew">
      <i class="iconfont icon-a-zujian215" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>奖励信息管理</div>
    </div>
    <div class="card item" @click="downJobTitle" v-if="authFun.workerDetail" v-loading="downJobTitleing">
      <i class="iconfont icon-copy" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>人员聘用情况下载</div>
    </div>
    <EditForm :is-absolut="false" :openDialog="status.editForm" @handleClose="status.editForm = false" :workerId="workerId" v-if="status.editForm"></EditForm>
  </div>
</template>

<script>
import {
  askLeaveRequest,
  dataAppleRequest
} from '../../api'

import EditForm from './edit_info/index'

import downWorkerJobTitle from './downWorkerJobTitle'

export default {
  inject: ['authFun', 'authJump'],
  components: { EditForm },
  data () {
    return {
      downJobTitleing: false,
      status: {
        editForm: false
      },
      askLeaveApplyCount: 0,
      wrokerEditApplyCount: 0
    }
  },
  computed: {
    workerId () {
      return this.$store.getters.currentWorkerId
    }
  },
  methods: {
    async loadAskLeaveApplyCount () {
      let parm = {
        status: '待审批'
      }
      let data = await askLeaveRequest.get(parm)
      this.askLeaveApplyCount = data.length
    },
    async downJobTitle () {
      this.downJobTitleing = true
      await downWorkerJobTitle()
      this.downJobTitleing = false
    },
    async loadWrokerEditApplyCount () {
      let data = await dataAppleRequest.getWait()
      let workerIds = {}
      data.forEach(v => {
        workerIds[v.workerId] = 1
      })
      this.wrokerEditApplyCount = Object.keys(workerIds).length
    }
  },
  mounted () {
    if (this.authFun.askLeaveApply) {
      this.loadAskLeaveApplyCount()
    }
    if (this.authFun.wrokerEditApply) {
      this.loadWrokerEditApplyCount()
    }
    this.$store.dispatch('loadCurrentWorkerId')
  }
}
</script>
