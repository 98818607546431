<style scoped lang="less">
  .full-over-height {
    flex: 1; height: 0; position: relative; display: flex;
    flex-direction: column;
  }
  .notice-list {
    overflow: auto;
    margin: 20px;
    .item {
      display: flex;
      align-items: flex-start;
      & + .item {margin-top: 10px;}
      .iconfont {
        position: relative;
        top: 5px;
        font-size: 20px;
        color: #707070;
      }
      .content {
        flex: 1;
        margin-left: 8px;
        .type {
          font-size: 14px;
          color: #707070;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .del-btn, .restore-btn {
        cursor: pointer;
        opacity: 0;
        transition: all .3s;
      }
      &:hover {
        .restore-btn, .del-btn {
          opacity: 1;
        }
      }
    }
  }
  .card-btn {
    background-color: #F0F3F8;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #3C3F54;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: #dfe8f7;
    }
  }
  .recycling {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: rgba(35, 50, 43, 0.5);
    text-align: center;
    display: inline-block;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      color: rgba(35, 50, 43, 1);
    }
  }
  .add-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    z-index: 1;
    display: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <div>
    <div class="add-form" v-if="status.addNotice">
      <fm-input-new type="textarea" placeholder="请输入内容" style="width: 100%;" />
      <div style="margin-top: 20px;">
        <div class="card-btn" @click="status.addNotice = false">新增</div>
        <div class="card-btn" style="margin-top: 20px;" @click="status.addNotice = false">取消</div>
      </div>
    </div>
    <div class="full-over-height notice-list">
      <div class="item" v-for="(item, i) in noticeList" :key="i + '-' + item.id" @click="clickNotice(item)">
        <i class="iconfont icon-a-zu1460"></i>
        <div class="content">
          <div class="type">{{item.type}}</div>
          <div class="text">{{item.content}}</div>
        </div>
        <!-- <i class="iconfont icon-Eye-closed restore-btn"></i>
        <i class="iconfont icon-Trash del-btn"></i> -->
      </div>
    </div>
    <!-- <div style="height: 100px;padding: 0 20px;">
      <div class="card-btn" @click="status.addNotice = true">+ 新 增</div>
      <div class="recycling" @click="toggleNotice">
        <i class="iconfont icon-User-clock" v-if="status.isHis"></i>
        <i class="iconfont icon-Trash" v-else></i>
        <div>{{status.isHis ? '返回' : '回收站'}}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  officialDocumentHandleRequest,
  dataAppleRequest
} from '../../api'

export default {
  data () {
    return {
      status: {
        addNotice: false,
        isHis: false
      },
      docDoticeList: [],
      modifyApplyList: [],
    }
  },
  computed: {
    noticeList () {
      return this.docDoticeList.concat(this.modifyApplyList)
    },
    authFun () {
      const isAdmin = (this.$store.getters.currentRole && this.$store.getters.currentRole.id === 1)
      return {
        myDocWarning: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.doc.handle-get')),
        modifyApply: isAdmin || (this.$store.getters.currentFunKeys.includes('hrms.worker.worker_edit-get')),
      }
    }
  },
  created () {
    if (this.authFun.myDocWarning) {
      this.loadDocNotice()
    }
    if (this.authFun.modifyApply) {
      this.loadModifyApplyNotice()
    }
  },
  methods: {
    clickNotice (data) {
      if (data.router) {
        this.$router.push(data.router)
      }
    },
    async toggleNotice () {
      this.status.isHis = !this.status.isHis
    },
    async loadDocNotice () {
      let datas = await officialDocumentHandleRequest.getMy()
      let dataMap = {}
      datas.forEach(v => {
        let key = v.officialDocumentId + '_' + v.workerId
        if (!dataMap[key]) {
          dataMap[key] = v
        } else {
          dataMap[key].status = v.status === 'over' ? 'over' : 'wait'
        }
      })
      this.docDoticeList = Object.keys(dataMap).map(v => dataMap[v]).filter(v => v.status === 'wait').map(v => {
        return {
          type: '公文处理',
          content: '来至于' + (v.sendOrgName || '无来问单位') + '的《' + (v.officialDocumentTitle || '无标题') + '》。',
          router: {name: 'hrms.doc.handle'}
        }
      })
    },
    async loadModifyApplyNotice () {
      let datas = await dataAppleRequest.getWait()
      this.modifyApplyList = datas.map(v => {
        return {
          type: '待审核',
          content: v.workerName + '提交的' + v.num + '条信息变更申请。',
          router: {name: 'hrms.worker.worker_edit'}
        }
      })
    }
  }
}
</script>